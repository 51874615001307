
























































































































import {$} from '@/facade'
import _ from 'lodash'
import VueI18n from 'vue-i18n'
import {Modal} from '@simpli/vue-modal'
import TranslateResult = VueI18n.TranslateResult
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputSelect} from '@simpli/vue-input'
import {DappCollection} from '@/model/collection/DappCollection'
import {FilterDappSchema} from '@/schema/resource/Dapp/FilterDappSchema'
import FilterTransactionView from '@/components/filters/FilterTransactionView.vue'
import FilterDappFiltered from '@/components/filters/FilterDappFiltered.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import FilterDappMobile from '@/components/filters/FilterDappMobile.vue'
import ToggleButton from '@/components/misc/ToggleButton.vue'

type SortOption = {
  $id: number
  field: string
  asc: boolean
  $tag: TranslateResult
}

@Component({
  components: {
    ToggleButton,
    FilterDappMobile,
    FilterDappFiltered,
    FilterTransactionView,
    InputSelect,
    Modal,
  },
})
export default class FilterDapp extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: DappCollection
  @Prop({type: String, required: false, default: ''}) hideFields!: string
  @Prop({type: Boolean, required: false, default: false}) activeInLine!: boolean
  @Prop({type: Boolean, required: true}) isDapp!: boolean

  showModal: boolean = false
  schema = new FilterDappSchema()
  sorterField!: SortOption
  sortOptions: SortOption[] = this.isDapp
    ? [
        {
          $id: 1,
          field: 'publishedDate',
          asc: false,
          $tag: $.t('schema.FilterDapp.newToOld'),
        },
        {
          $id: 2,
          field: 'recentTransactionCount',
          asc: true,
          $tag: $.t('schema.FilterDapp.txLow'),
        },
        {
          $id: 3,
          field: 'recentTransactionCount',
          asc: false,
          $tag: $.t('schema.FilterDapp.txHigh'),
        },
        {
          $id: 4,
          field: 'title',
          asc: true,
          $tag: $.t('schema.FilterDapp.dappNameLow'),
        },
        {
          $id: 5,
          field: 'title',
          asc: false,
          $tag: $.t('schema.FilterDapp.dappNameHigh'),
        },
      ]
    : [
        {
          $id: 1,
          field: 'publishedDate',
          asc: false,
          $tag: $.t('schema.FilterDapp.newToOld'),
        },
        {
          $id: 2,
          field: 'title',
          asc: true,
          $tag: $.t('schema.FilterDapp.appNameLow'),
        },
        {
          $id: 3,
          field: 'title',
          asc: false,
          $tag: $.t('schema.FilterDapp.appNameHigh'),
        },
      ]

  get schemaAllFields() {
    return this.schema.allFields.filter(field => this.canShow(field))
  }

  async created() {
    const [selectedOption] = this.sortOptions
    this.sorterField = _.cloneDeep(selectedOption)
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionCategory.queryAsPage(),
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  canShow(field: string): boolean {
    return !this.hideFields.includes(field)
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.querySearch())
  }

  doClear() {
    this.collection.dappCategory = []
    this.collection.dappWallet = []
    this.collection.dappBlockchainVersion = null
    this.collection.inActiveDevelopment = null
    this.collection.isDapp = false
    this.doFilter()
  }

  onSortChange() {
    this.collection.setOrderBy(this.sorterField.field)
    this.collection.setAsc(this.sorterField?.asc || false)
    this.collection.setCurrentPage(0)
    this.doFilter()
  }

  inputClass(field: string): string {
    if (field !== 'dappBlockchainVersion') {
      return 'multiselect--hide-tags hidden md:block'
    }
    return 'multiselect--hide-tags-round-selector hidden md:block'
  }

  toggleModal(): void {
    this.showModal = !this.showModal
  }
}
