/**
 * List Schema of Dapp
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Dapp} from '@/model/resource/Dapp'

/* TODO: review generated schema */
export class ListDappSchema extends DefaultSchema {
  readonly name = 'ListDapp'

  readonly fieldSet: FieldSet<Dapp> = {
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    slug: (): FieldComponent => ({
      is: Component.Render,
    }),
    categoryNamesCache: (): FieldComponent => ({
      is: Component.Render,
    }),
    shortDescription: (): FieldComponent => ({
      is: Component.Render,
    }),
    websiteUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
    companyName: (): FieldComponent => ({
      is: Component.Render,
    }),
    description: (): FieldComponent => ({
      is: Component.Render,
    }),
    blockchainVersionTitle: (schema): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
