









































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {DappCollection} from '@/model/collection/DappCollection'
import FilterTransactionView, {
  transactionOptions,
} from '@/components/filters/FilterTransactionView.vue'
import {Category} from '@/model/resource/Category'
import {Wallet} from '@/model/resource/Wallet'
import {NntTag} from '@/model/resource/NntTag'
import {Token} from '@/model/resource/Token'

@Component({
  components: {FilterTransactionView},
})
export default class FilterDappFiltered extends Vue {
  @Prop({type: Object, required: true}) collection!: DappCollection
  @Prop({type: String}) showOnly!: string

  get hasFilteredItems() {
    return (
      this.walleties?.length ||
      this.categories?.length ||
      this.tags?.length ||
      this.tokens?.length
      // TODO: Implement this logic
      // this.collection.min ||
      // this.collection.max ||
      // this.collection.date
    )
  }

  get categories() {
    if (!this.showOnly || this.showOnly === 'dappCategory') {
      return this.collection.dappCategory
    }
    return []
  }

  get walleties() {
    if (!this.showOnly || this.showOnly === 'dappWallet') {
      return this.collection.dappWallet
    }
    return []
  }

  get tags() {
    if (!this.showOnly || this.showOnly === 'dappNntTag') {
      return this.collection.dappNntTag
    }
    return []
  }

  get tokens() {
    if (!this.showOnly || this.showOnly === 'dappToken') {
      return this.collection.dappToken
    }
    return []
  }

  get dateByValue() {
    // const {text} =
    //   transactionOptions.find(item => item.value === this.collection.date) || {}
    const {text} = transactionOptions.find(item => item.value) || {}

    return this.$t(text as string)
  }

  @Emit('filter')
  clearCategory(category: Category) {
    const {dappCategory} = this.collection
    this.collection.dappCategory =
      dappCategory?.filter(item => item.$id !== category.$id) || []
  }

  @Emit('filter')
  clearWallet(wallet: Wallet) {
    const {dappWallet} = this.collection
    this.collection.dappWallet =
      dappWallet?.filter(item => item.$id !== wallet.$id) || []
  }

  @Emit('filter')
  clearTntTag(nntTag: NntTag) {
    const {dappNntTag} = this.collection
    this.collection.dappNntTag =
      dappNntTag?.filter(item => item.$id !== nntTag.$id) || []
  }

  @Emit('filter')
  clearToken(token: Token) {
    const {dappToken} = this.collection
    this.collection.dappToken =
      dappToken?.filter(item => item.$id !== token.$id) || []
  }

  @Emit('filter')
  clearTransaction() {
    // TODO: implement this logic
    // this.collection.min = null
    // this.collection.max = null
    // this.collection.date = null
  }

  @Emit('filter')
  clearBlockchainVersion() {
    this.collection.dappBlockchainVersion = null
  }

  doClear() {
    switch (this.showOnly) {
      case 'dappToken': {
        this.collection.dappToken = []
        break
      }
      case 'dappCategory': {
        this.collection.dappCategory = []
        break
      }
      case 'dappNntTag': {
        this.collection.dappNntTag = []
        break
      }
      case 'dappWallet': {
        this.collection.dappWallet = []
        break
      }
      case 'dappBlockchainVersion': {
        this.collection.idBlockchainVersionFk = null
        break
      }
      default: {
        this.collection.dappToken = []
        this.collection.dappCategory = []
        this.collection.dappNntTag = []
        this.collection.dappWallet = []
        this.collection.idBlockchainVersionFk = null
      }
    }
    this.clearTransaction()
  }
}
